// Autocomplete
.form-autocomplete {
  position: relative;

  .form-autocomplete-input {
    align-content: flex-start;
    background: #fff;
    border: .1rem solid @control-color;
    border-radius: @border-radius;
    color: @body-font-color;
    display: flex;
    flex-wrap: wrap;
    min-height: 3.6rem;
    outline: 0;
    padding: .1rem 0 .1rem .2rem;
    width: 100%;

    .form-input {
      border-color: transparent;
      box-shadow: none;
      display: inline-block;
      flex: 1 0 auto;
      padding: .3rem;
      width: auto;
    }
  }
}
