// Empty states (or Blank slates)
.empty {
  background: @core-empty-bg;
  border-radius: @border-radius;
  text-align: center;
  padding: 4 * @layout-gutter;

  .empty-title {
    font-size: 1.8rem;
    margin: 1.5rem 0 .5rem 0;
  }
  .empty-meta {
    color: @core-meta-color;
  }
  .empty-action {
    margin-top: 1.5rem;
  }
}
