// Modals
.modal {
  align-items: center;
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;

  &.active {
    display: flex;
    opacity: 1;
    z-index: 1988;

    .modal-overlay {
      background: fade(@core-dark-color, 50%);
      bottom: 0;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
    .modal-container {
      animation: slide-down .216s;
    }
  }
}
.modal-container {
  animation: slide-up .216s;
  background: #fff;
  border-radius: @border-radius;
  display: block;
  margin: 0 auto;
  padding: 0;
  .shadow;
  text-align: left;
  z-index: 1988;

  .modal-header {
    padding: 1.5rem;

    .modal-title {
      font-size: 1.5rem;
      margin: 0;
    }
  }
  .modal-body {
    max-height: 50vh;
    overflow-y: auto;
    padding: 1.5rem;
    position: relative;
  }
  .modal-footer {
    padding: 1.5rem;
    text-align: right;
  }
}
@media screen and (min-width: 640px) {
  .modal-container {
    width: 64rem;
  }
}
@media screen and (min-width: 320px) {
  .modal-sm {
    .modal-container {
      width: 32rem;
    }
  }
}
