// Menus
.menu {
  background: #fff;
  border-radius: @border-radius;
  display: block;
  margin: 0;
  min-width: @control-min-width;
  padding: .5rem;
  .shadow;
  text-align: left;
  transform: translateY(.5rem);
  z-index: 999;

  .menu-item {
    border-radius: @border-radius;
    color: @body-font-color;
    display: block;
    line-height: 2.4rem;
    margin-top: .1rem;
    padding: .2rem .8rem;
    text-decoration: none;
    user-select: none;

    a {
      border-radius: @border-radius;
      color: inherit;
      display: block;
      margin: -.2rem -.8rem;
      padding: .2rem .8rem;
      text-decoration: none;
      &:focus,
      &:hover {
        color: @core-link-color;
      }
      &:active,
      &.active {
        background: @core-secondary-color;
        color: darken(@core-link-color, 5%);
      }
    }
  }

  .menu-header {
    color: lighten(@body-font-color, 60%);
    display: block;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-top: 0;
    padding: .2rem .8rem;

    .menu-header-text {
      background: #fff;
      display: inline-block;
      margin-left: -.6rem;
      padding: 0 .6rem;
      position: relative;
      z-index: 99;
    }

    &::after {
      border-bottom: .1rem solid @core-border-color;
      content: "";
      display: block;
      height: .1rem;
      transform: translateY(-1rem);
      width: 100%;
    }
  }
}
