// Cards
.card {
  background: #fff;
  border: .1rem solid @core-border-color;
  border-radius: @border-radius;
  display: block;
  margin: 0;
  padding: 0;
  text-align: left;

  .card-header,
  .card-body,
  .card-footer {
    padding: 1.5rem 1.5rem 0 1.5rem;

    &:last-child {
      padding-bottom: 1.5rem;
    }
  }
  .card-image {
    padding-top: 1.5rem;

    &:first-child {
      padding-top: 0;

      img {
        border-top-left-radius: @border-radius;
        border-top-right-radius: @border-radius;
      }
    }

    &:last-child {
      img {
        border-bottom-left-radius: @border-radius;
        border-bottom-right-radius: @border-radius;
      }
    }
  }
  .card-title {
    font-size: 1.4em;
    line-height: 1;
    margin-bottom: .5rem;
    margin-top: 0;
  }
  .card-meta {
    color: lighten(@body-font-color, 50%);
    font-size: 1em;
    margin-bottom: 0;
    margin-top: 0;
  }
}
