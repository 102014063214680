// Navigation
.breadcrumb,
.tab,
.pagination {
  list-style: none;
  margin: .5rem 0;
}
// Breadcrumbs
.breadcrumb {
  padding: 1.2rem;

  .breadcrumb-item {
    display: inline-block;
    margin: 0;

    a {
      text-decoration: none;
    }
    &:last-child {
      &,
      a {
        color: @body-font-color;
      }
    }
    &:not(:last-child) {
      &::after {
        color: @control-color;
        content: "/";
        padding: 0 .4rem;
      }
    }
  }
}

// Tabs
.tab {
  border-bottom: .1rem solid @core-border-color;
  display: flex;
  flex-wrap: wrap;

  .tab-item {
    margin-top: 0;

    a {
      border-bottom: .2rem solid transparent;
      color: @body-font-color;
      display: block;
      margin-bottom: -.1rem;
      margin-top: 0;
      padding: .8rem 1rem;
      text-decoration: none;
      &:focus,
      &:hover {
        color: @core-link-color;
      }
    }
    &.active a,
    a.active {
      border-bottom-color: @core-color;
      color: @core-link-color;
    }
  }

  &.tab-block {
    .tab-item {
      flex: 1 0 auto;
      text-align: center;

      .badge {
        &[data-badge]::after {
          position: absolute;
          right: -1.4rem;
          top: 1rem;
          transform: translate(-50%, -1rem);
        }
      }
    }
  }
}

// Pagination
.pagination {
  .page-item {
    display: inline-block;

    span {
      display: inline-block;
      padding: .6rem;
    }

    a {
      border-radius: @border-radius;
      color: @body-font-color;
      display: inline-block;
      padding: .6rem .8rem;
      text-decoration: none;
      &:focus,
      &:hover {
        color: @core-color;
      }
    }

    &.active {
      a {
        background: @core-color;
        color: @core-light-color;
      }
    }
  }
}
