// Navbar
.navbar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .navbar-section {
    align-items: center;
    display: flex;
    padding: 1rem 0;
  }
}
